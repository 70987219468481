.theme-blackcyan {

    background-color:#9dd1ff !important;

    * {

        background-color: #9dd1ff !important;
        color: black !important;
        border-color: black !important;

        &:after,
        &:before {

            background: none;
            background-color: #9dd1ff !important;
            border-color: black !important;
            color: black !important;

        }
    }

    button {
        border:black 1px solid;
    }

    .g-burger-acc {
        background-image: url(/images/burger_black.svg) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 75% 75%;
    }

    .g-search-acc {
        background-image: url(/images/search_black.svg) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
    }

    .g-eye-acc {
        background-image: url(/images/eye_black.svg) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 75% 75%;
    }
}
