.theme-whiteblack {

    background-color: black !important;

    *{

        background-color: black !important;
        color: white !important;
        border-color: white !important;

        &:after,
        &:before {

            background: none;
            background-color: black !important;
            border-color: white !important;
            color: white !important;

        }
    }

    button {
        border: white 1px solid;
    }

    .g-burger-acc {
        background-image: url(/images/burger_white.svg) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 75% 75%;
    }

    .g-search-acc {
        background-image: url(/images/search_white.svg) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
    }

    .g-eye-acc {
        background-image: url(/images/eye_white.svg) !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 75% 75%;
    }
}
