@import 'black-cyan';
@import 'black-white';
@import 'white-black';

body.accessibility-enabled {

    margin-top: 55px;

    @media screen and (max-width: 39.9375em) {

        margin-top: 30px;

    }

    @media screen and (min-width: 40em) and (max-width: 63.9375em) {

        margin-top: 30px;

    }

}

.accessibility-panel * {

    font-size: 12pt !important;
    letter-spacing: 0px !important; 

}

.but-accessibility {

    background:none;
    border:1px white solid;
    font-size:14px;
    padding: 5px;
    background-position: center;
    border: none !important;

}

.accessibility-panel {

    width: 100%;
    height: 55px;
    display: none;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999999999;
    font-size: 12pt !important;
    border-bottom: 2px gray solid;
    box-sizing: border-box;

    @media screen and (max-width: 39.9375em) {

        height: auto;
        flex-wrap: wrap;

    }

    @media screen and (min-width: 40em) and (max-width: 63.9375em) {

        height: auto;
        flex-wrap: wrap;

    }

    &.disabled {

        display: none !important;

    }

    &.enabled {

        display: flex !important;

    }

    input[type=checkbox] {

        display: none;

    }

    .column-hidden {

        width: 100%;
        height: 30px;
        margin: 0 auto;
        box-sizing: border-box;
        flex-grow: 1;
        display: none;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid #000;

        @media screen and (max-width: 39.9375em) {

            display: flex;

        }

        @media screen and (min-width: 40em) and (max-width: 63.9375em) {

            display: flex;

        }

    }

    #column-hidden:checked ~ .column {

        display: block;

    }

    .column {

        padding:14px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex: 1 1 0;
        text-align: center;
        border-right:2px gray solid;
        border-bottom:2px gray solid;
        align-items: center;

        @media screen and (max-width: 39.9375em) {

            padding: 5px 0;
            display: none;
            flex-basis: auto;

        }

        @media screen and (min-width: 40em) and (max-width: 63.9375em) {

            padding: 5px 0;
            display: none;
            flex-basis: auto;

        }

        button {

            margin: 0 5px;

        }

    }

}

.font,
.destroy {

    background:white;
    color : black;

}


.but-theme-blackwhite,
.but-theme-whiteblack,
.but-theme-blackcyan,
.font,
.destroy {

    border: gray 1px solid !important;

}


.but-theme-blackwhite {

    color: black !important;
    background-color: white !important;

}

.but-theme-whiteblack {

    color: white !important;
    background-color: black !important;

}

.but-theme-blackcyan {

    color: black !important;
    background-color:#9dd1ff !important;

}

.theme-whiteblack,
.theme-blackwhite,
.theme-blackcyan {

    background-image: none !important;
    box-shadow: none !important;
    text-shadow: none !important;

    * {

        background-image: none !important;
        box-shadow: none !important;
        text-shadow: none !important;

        &:after {

            background-image: none !important;

        }

        &:before {

            background-image: none !important;

        }

    }

    img {

        visibility: hidden;

    }

}
